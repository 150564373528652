.amcscreen-container {
    background-color: black;
    color: white;
    min-height: 100vh;
	width: 100%;
	overflow-x: hidden;
}

.user-detail-container {
	margin: 15px;
}

.user-detail-heading {
	font-size: 15px;
}

.user-detail {
	font-size: 24px;
}

.roundn-container {
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
}
.amc-submit-btn-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.amc-submit-button {
	margin: 15px 0px;
	border: 0px;
	outline: none;
	padding: 6px 9px;
	border-radius: 6px;
	font-size: 18px;
	background-color: #0088FF;
	color: white;
}

.amc-submit-button:hover {
	cursor: pointer;
}

.amc-submit-button:disabled {
	background-color: grey;
}

.roundn-choice-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
}

.roundn-status-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 10px 0px;
}

.choice-container {
	display: flex;
	flex-direction: row;
	margin: 6px 10px;
	height: 20px;
}

.roundn-details-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	background-color:  white;
	border-radius: 9px;
	padding: 3px;
}

.roundn-details-heading {
	font-size: 18px;
	margin: 3px;
	font-weight: 550;
	text-align: center;
	color: black;
}

textarea {
	border: 0px;
}
