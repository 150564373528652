.modal-container {
	background-color: black;
	color: white;
	width: 100%;
}

.slot-selected {
	background-color: #0088FF;
	color: white;
}

.modal-slot-heading {
	margin: 6px 0px 6px 6px;
}

.modal-book-btn-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 18px 0px;
}
