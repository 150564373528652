.dashboardscreen-container {
    background-color: black;
    color: white;
    height: 100vh;
    width: 100vw;
}

.dashboardscreen-header-titles {
	margin: 3em;
}

.dashboardscreen-dontmessup {
	font-size: 60px;
	font-weight: 400;
}

.roundbuttons-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.roundbuttons-inner-div {
	height: 240px;
	width: 240px;
	background-color: white;
	margin: 0.5em;
	border-radius: 10px;
	color: black;
}

.roundbuttons-inner-div-header {
	text-align: center;
	margin: 5px;
}

.round-button {
	cursor: pointer;
	padding: 0.5em 1em;
}

.roundbuttons-inner-div-body {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	height: 80%;
}