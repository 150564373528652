.profilescreen-container {
    background-color: black;
    color: white;
    height: 100vh;
	width: 100vw;
	padding: 2em;
}

.profilescreen-input-container {
	margin: 1em;
	font-size: 1.5em;
}

.profilescreen-input-container label {
	color: grey;
}

.profilescreen-input-container input {
	height: 30px;
	width: 400px;
	font-size: 1em;
}

.good-looking-button {
	background-color: #0088FF;
	outline: none;
	border-radius: 5px;
	padding: 5px 10px;
}