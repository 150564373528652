.loginpage-container {
    background-color: black;
    color: white;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loginpage-login-container {
    display: flex;
    flex-direction: column;
}

.loginpage-login-container input {
    color: white;
    background-color: black;
    margin: 0.5em 0;
    padding: 0.5em;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #0088FF;
    outline: none;
}

.loginpage-login-container button {
    margin: 1em 0;
    padding: 0.5em 0;
    background-color: #0088FF;
    border: none;
}