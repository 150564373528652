.meetscreen-container {
    background-color: black;
    color: white;
    height: 100vh;
    width: 100vw;
}

.meetscreen-enter-container {
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.meetscreen-container input {
    color: white;
    background-color: black;
    margin: 0.5em 0;
    padding: 0.5em;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #0088FF;
    outline: none;
}

.meetscreen-submit-buttton {
    width: 100px;
    margin: 1em 0;
    padding: 0.5em 1em;
    background-color: #0088FF;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}