.navbar-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
	padding: 1em 4em;
	border-bottom: 1px solid #0088FF;
}

.navbar-button {
    border: 1px solid #0088FF;
    color: #0088FF;
    background-color: black;
    border-radius: 2px;
    padding: 0.5em 1em;
    margin: 0 0.5em;
}

.navbar-button:hover {
    background-color: white;
    cursor: pointer;
}