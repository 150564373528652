.managementpoolcandidatelistscreen-container {
	background-color: black;
    color: white;
    height: 100vh;
	width: 100vw;
}

.center-loading {
	display: flex;
	justify-content: center;
	align-items: center;
}

.managementpoolcandidatelistscreen-container h2 {
	padding: 1em;
}

.managementpoolcandidatelistscreen-inner-container {
	padding-left: 1em;
}

.managementpoolcandidatelistscreen-inner-container select { 
	margin: 0.5em;
}