.roundonescreen-container {
	background-color: black;
	color: white;
	min-height: 100vh;
    /* height: 100vh; */
	/* width: 99vw; */
	max-width: 100%;
	overflow-x: hidden;
}

.goback-button {
	margin: 0.5em 2em;
	border: none;
	color: #0088FF;
	background-color: black;
	font-size: 20px;
}

.candidate-table {
	/* margin: 1em 2em; */
	width: 100%;
	table-layout: fixed;
}

.candidate-table-row-title {
	/* width: 25%; */
	text-align: left;
}

.candidate-table-row {
	background-color: #181818;
	border-radius: 5px;
}

.candidate-table-row-data {
	/* width: 25%; */
	padding: 1em;
	background: none;
}

.candidate-table-row-data-interview {
	margin-left: 5px;
	border-radius: 5px;
	width: fit-content;
	font-family: "Fira Sans", sans-serif;
	font-size: 14px;
	letter-spacing: 1px;
	background-color: #181818;
	border: none;
	color: #0088FF;
	padding: 5px 10px;
	border: 2px solid #0088FF;
	box-shadow: 0px 0px 2px 0px #6e6e6e;
	cursor: pointer;
}

.candidate-table-row-data-interview:hover {
	margin-left: 5px;
	border-radius: 5px;
	width: fit-content;
	font-family: "Fira Sans", sans-serif;
	font-size: 14px;
	letter-spacing: 1px;
	background-color: #0088FF;
	border: none;
	color: white;
	padding: 5px 10px;
	border: 2px solid #0088FF;
	box-shadow: 0px 0px 2px 0px #6e6e6e;
	cursor: pointer;
}